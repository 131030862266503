*{
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  letter-spacing: 0.3px;

}
.navbar ul li{
  text-transform: uppercase;  
}

.active{
  font-size: larger;
}

.textInfo{
  text-align: justify;
  margin-left: 40vh;
  color: white;
  width: 70vh;
  text-wrap: wrap;
  transform: translate(0vh,-70vh);
  z-index: 1;
  position: relative;
  margin-bottom: -25vh;
}
.downloadButton{
  padding: 6px 12px 6px 12px ;
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
}
.downloadButton:hover{
  color: #000;
  background-color: #fff;
}
.aboutMe{
text-align: justify;
margin-left: 10px;
}
.profileImg .img-fluid{
  height: 40vh;
  width: 40vh;
  border-radius: 60%;
}
.imgStyle{
  margin-top: -2vh;
    z-index: 1;
    height: 100vh;
}
.hireButton{
  padding: 6px 12px 6px 12px ;
  background-color: transparent;
  border: 1px solid #000;
  color: #000;
  text-transform:uppercase ;
}
.hireButton:hover{
  color: #fff;
  background-color: #000000d5;
  border: none;
}
.text-justify{
  text-align: justify;
}
.card-img{
  height: 1rem;
}
@media only screen and (max-width: 1200px){
  .textInfo{
    text-align: justify;
    margin-left: 15vh;
    color: white;
    width: 40vh;
    text-wrap: wrap;
    transform: translate(0vh,-70vh);
    z-index: 1;
    position: relative;
    margin-bottom: -15vh;
  }
}

@media only screen and (max-width: 600px){
  .textInfo{
    text-align: justify;
    margin-left: 10vh;
    color: white;
    width: 40vh;
    text-wrap: wrap;
    transform: translate(0vh,-70vh);
    z-index: 1;
    position: relative;
    margin-bottom: -15vh;
  }
}
@media only screen and (max-width: 425px){
	.textInfo{
    text-align: justify;
    margin-left: 5vh;
    color: white;
    width: 40vh;
    text-wrap: wrap;
    transform: translate(0vh,-70vh);
    z-index: 1;
    position: relative;
    margin-bottom: -15vh;
  }
}